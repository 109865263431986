import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { useContext } from 'react';
import { ChannelContext } from '../contexts/ChannelsContext';

export default function SideDrawer(props, children) {
  const context = useContext(ChannelContext)
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    context.toggleDrawer(open);
  };

  return (
    <div>
      <>
          <Button variant="contained" size='large' onClick={toggleDrawer({open: true, is_edit: context.selectedChannel.is_edit ? true: false })}>{props.buttonText}</Button>
          <Drawer
            anchor={'right'}
            open={context.isOpen}
            onClose={toggleDrawer({open: false, is_edit: false})}
          >
            {props.children}
          </Drawer>
      </>
    </div>
  );
}
